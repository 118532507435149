body{
    
    overflow-x:hidden ;
}
.degra3{
    width: 100vw;
    height: 100vh;
    position: absolute;
    left:0%;
    top:0%;
    background: linear-gradient( #009ADE 0%, #00AB9F 35%, #FCFCFC);
    background-repeat: no-repeat   ;
    
    z-index: -1;
}
.degra{
    width: 100vw;
    height: 60vh;
    position: absolute;
    left:0%;
    top:0%;
    background: linear-gradient( #009ADE 0%, #00AB9F 35%, #FCFCFC);
    background-repeat: no-repeat   ;
    
    z-index: -1;
}

  @keyframes charge {
    0%{
        transform: translateX(-50%) rotate(0deg) ;
      }
      50%{
        transform: translateX(-50%) rotate(180deg);
      }
      100%{
        transform: translateX(-50%) rotate(360deg);
      }
  }
  .chargement{
    height: 50px;
    animation-name: charge;
    transition: .3s all ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    margin-left: 50%;

    margin-bottom: 3em;
   
    z-index: 10000;
  }
h1{
    font-size: 5rem;
    font-weight: 700;
    text-shadow: 1px 1px 10px black;
    color: white;
   
}
.title_div_programme{
    
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15vh;

}
.tele{
    text-decoration: none;
    text-align: center;
    width: 100%;
    color: rgb(49, 49, 49);
    cursor: default;
}
.downloadlink{
    text-decoration: underline;
    cursor: pointer;
    color: rgb(46, 46, 46);

}
.progr_p{
    font-size: 1.5rem;
    text-shadow: 1px 1px 30px black;
}
#img_wrap_programme{
    
    width: 100vw;
    justify-content: center;
    text-align: center;
}

.programmeimgs{
    width: 90vw;
  
}
p{
    display: block;
    width: 60vw;
    color: white;
    text-align: left;
    padding: 0;
 

}
p span{
    text-decoration: underline;
    cursor: pointer;
    
}
.wrap_programme{
    
    width: 98vw;
    
    margin-top: 10vh;
    padding: 1vw;

}

.wrap_section_programme{
    
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.BigLeft_programme{
   
    background-size: cover;
    width: 49%;
    height: 98%;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
}
.BigLeft_programme h3{
    left: 2%;
    top: 2%;
    font-size: 3rem;
    font-weight: 600;
    text-shadow: none;
    width: 100%;
    position: absolute;

}
.BigLeft_programme .h6_programme{
    left: 2%;
    bottom: 2%;
    font-size: 1.3rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    margin: 0;
    padding:0;
    font-style: normal;
    color: #FCFCFC;

}
.wrap_mini_sections_programme{
    
    width: 49%;
    height: 98%;
}
.wrap_little_programme{
    width: 100%;
    
    height: 49%;
    display: flex;
   
}
.mini_1_programme{
    height: 100%;
    width: 49%;
    
    background-size: cover;
    margin-right: 1%;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
}
.h3_little {
    left: 2%;
    top: 2%;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    width: 100%;

}
.mini_1_programme .h6_programme{
    left: 2%;
    bottom: 2%;
    font-size: 1rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    margin: 0;
    padding:0;
    font-style: normal;
    color: #FCFCFC;

}
.mini_2_programme{
    margin-left: 1%;
    height: 100%;
    width: 49%;
   
    background-size: cover;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
}
.h3_little{
    left: 2%;
    top: 2%;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    width: 100%;

}
.mini_2_programme .h6_programme{
    left: 2%;
    bottom: 2%;
    font-size: 1rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    margin: 0;
    padding:0;
    color: #FCFCFC;
    font-style: normal;

}

.mid_programme{
    width: 100%;
    
    background-size: cover;
    height: 49%;
    margin-top: 2%;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
}
.h3_little{
    left: 2%;
    top: 2%;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    width: 50%;


}
.mid_programme .h6_programme{
    left: 2%;
    bottom: 2%;
    font-size: 1rem;
    font-weight: 600;
    text-shadow: none;
    position: absolute;
    margin: 0;
    padding:0;
    font-style: normal;
    color: #FCFCFC;

}



.background_info_theme_program{
    height: 40vh;
    position: relative;
    
}
.programme_theme_h1{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    font-size: 3rem;
   
}
.idee_prog_h4{
    font-size: 1.5rem;
    margin: 0;
    width: fit-content;
    
}
.idee_prog_p{
    font-size: 1.3rem;
    color: black;
    width: 100%;
   
}
.idee_div_prog_expli{
    text-align: left;
    padding: 1em;
    
}
.linkreact{
    text-decoration: none;
    color: white;
    transition: .3s all ease-in-out;
}
.idee_div_prog{
    display: flex;
    background-color: white;
    margin-top: 2em;
    box-shadow: 1px 1px 30px black;
    width: 90vw;
    height: fit-content;
    justify-content: space-between;
    margin-left: 50%;
    transform: translateX(-50%);
    align-items: center;
    margin-bottom: 1em;

}
.idee_prog_img{
    height: 80vh;
    width: 100%;
    background-size: cover;
}
.recource{
    height: 90vh;
    width: 90vw;
    margin-left: 50%;
    transform: translateX(-50%);
    display: none;
}
.download_info{ 
    background-color: transparent;
    text-decoration: underline;
    border: none;
    font-size: 1rem;
    margin-left: 50%;
    transform: translateX(-50%);
   
    cursor: pointer;
    margin-top: 2em;
}
.download_inf{ 
    background-color: transparent;
    font-weight: 600;
    
    border: none;
    font-size: 1rem;
    margin-left: 50%;
    transform: translateX(-50%);
   
    cursor: pointer;
    margin-top: 2em;
}
.download_prog_div{
    margin-bottom:2em ;
}
@media (max-width:941px) {
    .h3_little{
        font-size: 1rem;
    }
   
    .programme_h1{
        font-size: 3rem;
        margin-top: 3em;
    }
}
@media (max-width:815px) {
    .programme_h1{
        text-align: center;

    }
    .title_div_programme p{
        text-align: center;
        width: unset;
        text-shadow: 1px 1px 30px black;
    }
}
@media (max-width:710px) {
    .h3_little{
        font-size: .8rem;
    }
   
    .programme_h1{
        font-size: 3rem;
        margin-top: 3em;
    }
    .BigLeft_programme h3{
        left: 2%;
        top: 2%;
        font-size: 2rem;
        font-weight: 600;
        text-shadow: none;
        width: 100%;
        position: absolute;
    
    }
}
@media (max-width:625px) {
    .h3_little{
        font-size: .8rem;
    }
   
    .programme_h1{
        font-size: 3rem;
        margin-top: 3em;
    }
    .BigLeft_programme h3{
        left: 2%;
        top: 2%;
        font-size: 1.5rem;
        font-weight: 600;
        text-shadow: none;
        width: 100%;
        position: absolute;
    
    }
    .wrap_section_programme{
        height: 50vh;
    }
    .idee_prog_p{
        font-size: .7rem;
    }
    
}
@media (max-width:450px) {
    .h3_little{
        font-size: .8rem;
    }
   
    .programme_h1{
        font-size: 2.5rem;
        margin-top: 3em;
    }
    .programme_theme_h1{
        font-size: 2rem;
    }
    .BigLeft_programme h3{
        left: 2%;
        top: 2%;
        font-size: 1.5rem;
        font-weight: 600;
        text-shadow: none;
        width: 100%;
        position: absolute;
    
    }
    .wrap_section_programme{
        height: 40vh;
    }
    
}