.wrap_card_agenda{
    
    height: 30vh;
    background-size: cover;
    position: relative;
    border-radius: 3px;
}
.footer_agenda{
    background-color: #00AB9F;
    width: 90%;
    height: fit-content;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 10%;
    border-radius: 3px;
}
.footer_agenda h3{
    font-size: 2rem;
    font-weight: 600;
    text-shadow: none;
}
.h4_agenda{
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
    padding: 0;
    font-size: 1.3rem;
    color: white;
    width: unset;
}
.grid_agenda{
    display: grid;
    column-gap: 6.25vw;
    row-gap: 6.25vw;
    grid-template-columns: 360px 360px 360px ;
   margin-left: 50%;
   transform: translateX(-50%);
   width: fit-content;
    margin-top: 40vh;
    margin-bottom: 2em;
   
    
}
.degra1{
    width: 100vw;
    height: 60vh;
    position: absolute;
    left:0%;
    top:0%;
    background: linear-gradient( #009ADE 0%, #00AB9F 35%, #FCFCFC);
    background-repeat: no-repeat   ;
    
    z-index: -1;
}
@media (max-width:1297px) {
    .grid_agenda{
        display: grid;
        column-gap: 6.25vw;
        row-gap: 6.25vw;
        grid-template-columns: 360px 360px ;
     
        margin-top: 25vh;
        
       
        
    }
}
@media (max-width:844px) {
    .grid_agenda{
        display: grid;
        column-gap: 6.25vw;
        row-gap: 6.25vw;
        grid-template-columns: 360px  ;
       
        margin-top: 25vh;
        
       
        
    }
}
@media (max-width:768px) {
    .footer_agenda h3{
        font-size: 1.5rem;
        font-weight: 600;
        text-shadow: none;
    }
    .h4_agenda{
        font-size: .3rem;
        font-weight: 600;
        margin: 0;
        text-align: left;
        padding: 0;
        font-size: 1.2rem;
    }
}
    @media (max-width:425px) {
        .grid_agenda{
            display: grid;
            column-gap: 6.25vw;
            row-gap: 6.25vw;
            grid-template-columns: 90vw  ;
           
            margin-top: 25vh;
            
           
            
        }
    }