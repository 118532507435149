.background_propos{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/dev%2Fnature6.jpg?alt=media&token=4bceabd3-b70a-4a69-b066-5fd41cc647aa');
    background-size: cover;
    background-position: 50%;
    height: 50vh;
    width: 100vw;
    position: relative;
}
.propos_h1{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 4rem;
}
.div_histoir_propos{
    color: black;
    margin-left: 50%;
   width: fit-content;
   transform: translateX(-50%);
   margin-top: 10vh;
}
.h3_propos{
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
    text-shadow: none;
}
.p_propos{
    color: black;
    margin-top: 1em;
    font-size: 1.3rem;
}
.photo1{
    height: 30vh;
    width: 30vh;
    background-color: gray;
}
.galerie{


    margin-left: 19.5vw;
    margin-top: 10vh;
    overflow-x: scroll;

  display: flex;

}
.above_photo{
    display: flex;
}
.under_photo{
    display: flex;
    margin-top: 2vh;
}
.wrap_group_photo{
    margin-left: 2vh;
}
.photo2{
    width: 40vh;
    height: 30vh;
    background-color: rgb(160, 85, 85);
    margin-left:2vh;
}
.photo3{
    width: 40vh;
    height: 30vh;
    background-color: rgb(171, 82, 82);
}
.photo4{
    width: 30vh;
    height: 30vh;
    margin-left:2vh;
    background-color: gray;
}
.photo{
    box-shadow: 1px 1px 10px black;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:transparent;
    display: none;
  }
  .line{
    width: 30vw;
    background-color: black;
    height: 1px;
    margin-top: 10vh;
    margin-bottom: 10vh;
    margin-left: 50vw;
    transform: translateX(-50%);
  }
  .img_combat_propos{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/dev%2Fgroup.png?alt=media&token=dcd71a09-959e-4378-930d-ed5e47c58521');
    background-size: cover;
    height: 25vw;
    width: 30vw;
  }
  .ticket_cummunale{
    background-color:rgb(150 85 142) ;
    color: white;
    padding: .5em;
    font-size: 1.5vw;
    padding-left: 10%;
    padding-right: 10%;
    width: fit-content;
    position: absolute;
    z-index: 2;
    rotate: -40deg;
    bottom: 10%;
    right: -30%;

  }
  .cpas{
    background-color: #20a9a1;
  }
  @media (max-width:697px) {
    .ticket_cummunale{
        background-color:rgb(150 85 142) ;
        color: white;
        padding: .5em;
        font-size: 1.8vw;
        padding-left: 10%;
        padding-right: 10%;
        width: fit-content;
        position: absolute;
        z-index: 2;
        rotate: -40deg;
        bottom: 10%;
        right: -7vw;
    
      }
      .cpas{
        background-color: #20a9a1;
      }
  }
  @media (max-width:551px) {
    .ticket_cummunale{
        background-color:rgb(150 85 142) ;
        color: white;
        padding: .5em;
        font-size: 3vw;
        padding-left: 10%;
        padding-right: 10%;
        width: fit-content;
        position: absolute;
        z-index: 2;
        rotate: -40deg;
        bottom: 10%;
        right: -10vw;
    
      }
      .cpas{
        background-color: #20a9a1;
      }
  }
  .combat_div{
    display: flex;
    width: 100vw;
    justify-content: space-evenly;

  }
  .combat_texte{
    text-align: left;
  }

  .combats_p_propos{
    text-align: left;
    font-size: 1.3rem;
  }
 .propos_membres_h2{
    color: black;
    font-weight: 600;
    font-size: 3rem;
    text-shadow: none;
 }
 .membres{
    text-align: center;
    padding-top: 10vh;
    width: 100vw;
 }
 .grid_membres{
    margin-top: 10vw;
    margin-bottom: 5vw;
    display: grid;
    column-gap: 10vw;
    row-gap: 6vw;
    grid-template-columns: 25vw 25vw 25vw;
    margin-left: 50%;
   width: fit-content
   ;
   transform: translateX(-50%);

 }
 .wrap_membre{
    background-color: #F7F1F1;
    text-align: center;
    position: relative;
    padding-top: 1em;
    border-radius: 1em;
    height: fit-content;
    padding-bottom: 1em;
 }
 .info_membre h3{
    color: black;
    font-size: 2rem;
    font-weight: 600;
    text-shadow: none;
    margin: 0;
    text-align: left;
    padding: 0;
    margin-top: 1em;
    width:17vw;
    margin-left: 50%;
    transform: translateX(-50%);
 }
 .photo_membre{
    width: 23vw;
    height: 24vw;
    background-size: cover;
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 1em;

 }
 .info_membre h6{
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;

    margin: 0;
    text-align: left;
    padding: 0;
    width: 17vw;
    margin-left: 50%;
    transform: translateX(-50%);

 }
.bigimg{
    height: 50vw;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    display: none;
}
.delbigimg{
    position: fixed;
    left: 0;top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.423);
    cursor: pointer;
    z-index: 999;
    display: none;
}
 @media (max-width:809px) {
    .info_membre h3{
        font-size: 1.5rem
    }
    .info_membre h6{
        font-size: 1rem
    }
    .grid_membres{
        grid-template-columns: 30vw 30vw;
    }
    .photo_membre{
        height: 25vw;
        width: 25vw;


    }
    .img_combat_propos{
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10vh;
        width: 50vw;
        height: 40vw;
    }
    .combat_div{
        display: block;
    }
    .combats_p_propos{
        width: 80Vw;
    }
    .combats_h3_propos{

        text-align: left;
    }
    .combat_texte{
        width: fit-content;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .p_propos{
        width: 80Vw;
    }
    .galerie{
        margin-left: 0;
    }

 }
 .main_propos{
   overflow-x: hidden;
 }
 @media (max-width:761px) {
   .propos_h1{
     font-size: 3rem
   }
 }
@media (max-width:556px) {
    .propos_h1{
      font-size: 3rem;
      width: 100vw;
      text-align: center;

    }
 }
 @media (max-width:551px) {

    .grid_membres{

        width:80vw;

        display: block;


    }
    .membres{
      position: relative;
    }
    .wrap_membre{
      margin-top: 2em
    }
    .photo_membre{
        height: 70vw;
        width: 70vw;
    }
    .info_membre h3{
        font-size: 1.5rem;
        width: 80%;
    }
    .info_membre h6{
        font-size: 1rem;
        width: 80%;
    }
 }
