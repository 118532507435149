.h1_actu{
    text-align: center;
    margin-top: 15vh;
    
}
.actu_embed{
    margin-top: 0vh;
}

.degra2{
    width: 100vw;
    height: 100vh;
    position: absolute;
    left:0%;
    top:0%;
    background: linear-gradient( #009ADE 0%, #00AB9F 35%);
    background-repeat: no-repeat   ;
    
    z-index: -1;
}
.actud{
    position: fixed;
}
.actu_list{
   
    width: 100vw;
    height: fit-content;
    margin-top: 2em;
    margin-left: 50vw;
    transform: translateX(-50%);
    padding-top: 2em;
    background-color: transparent;
    height: 100%;
   
    overflow-x: hidden;

}
.article_actu{
    margin-top: 1em;
    margin-bottom: 3em;
    display: flex;
    background-color: white;
    box-shadow: 1px 1px 30px black;
    padding: 3em;
    border-radius: 1em;
    width: 70vw;
    margin-left: 50vw;
    transform: translateX(-50%);
}
.img_article_{
    
    width: 40%;
    border-radius: 1em;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.432);
}
.text_preview_article{
    color: black;
    width: fit-content;
    width: 50%;
    margin-left: 6%;
}
.text_preview_article_p{
    width: 100%;
    color: black;
    text-shadow: 0;
    font-size: 1.4rem;
}
.text_preview_article_h3{
    font-size: 2rem;
    text-shadow: 1px 1px 30px transparent;
    color: black;
    font-weight: 600;
}
.text_preview_article_date{
 
    text-shadow: 1px 1px 30px transparent;
    color: rgb(117, 117, 117);
    margin-top: 1em;
   
}
.wrap_article{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    width: fit-content;
    height: 90vh;
    z-index: 10000000;
    padding:2em;
    border-radius: 1em;
    overflow-y: scroll;
    display: none;
}
.subtitle_full_article{
    
    margin: 0;
    padding: 0;
    margin-top: .5em;
    margin-bottom: .5em;
   
}
.title_full_article{
    font-weight: 600;
    text-shadow: none;
    margin-top: .5em;
    font-size: 3rem;
}
.img_full_article{
    margin-top: 2em;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.39);
    border-radius: 1em;
    height: 30vh;
    width: 100%;
    margin-bottom: .5em;
    background-position: center;
    background-size: cover;
    

}
.img_ful_article{
    margin-top: 2em;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.39);
    border-radius: 1em;
 

    margin-bottom: .5em;
    background-position: center;
    background-size: cover;
    

}
.text_preview_article_p{
    margin-bottom: .5em;
    margin-top: .5
}
.background_img_article{
    margin: 0;
    width: 100%;
    box-shadow: none;
  
    background-position: center;
    background-size:contain;

}
.closearticle{
    height: 20px;
    margin-bottom: 2em;
    cursor:pointer;
}
.copy{
    height: 30px;
}
.btnpartage{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.linkc{
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    color: white;
    padding: 1em;
    border-radius: 1em;
    display: none;
    transition: .3s all ease-in-out;
}
.czc{
    cursor: pointer;
}
.linkc{
    background-color: rgb(126, 251, 126);
    padding: 1em;
    border-radius: 1em;
}
@media (max-width:1026px) {
    .img_article_{
        height: 300px;
        width: 100%;
        margin-bottom: 2em;
    }
    .article_actu{
        display: block;
    }
    .text_preview_article{
        width: 100%;
    }
}
@media (max-width:861px) {
    .img_article_{
        height: 300px;
        width: 100%;
        margin-bottom: 2em;
    }
    .article_actu{
        display: block;
    }
    .text_preview_article{
        width: 100%;
    }
    .text_preview_article_h3{
        font-size: 2rem;
    }
    .text_preview_article_p{
        font-size: 1rem;
    }
}
@media (max-width:594px) {
    .title_full_article{
        font-size: 3rem;
        margin-bottom: 1em;
    }
    .h1_actu{
        font-size: 2rem;
        margin-top:1em;
    }
    .t{
        margin-top: 1em;
    }
}