.popup_chrome{
    padding: 1em;
    border-radius: 1em;
    border-bottom-left-radius: .3em;
    background-color: rgb(249, 249, 249);
    color: black;
    font-size: 1rem;
    box-shadow: 1px 1px 30px black;
    width: 50vw;
}
.img_browser{
    height: 50px;
    background-color: black;
    padding: .5em;
    border-radius: 50%;
    cursor: pointer;
}
.big_browser{
    position: fixed;
    bottom: 2%;
    left: 2%;
}
.chrome{
    font-weight: 600;
}