@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
 margin: 0;
 padding: 0;
 font-family: 'Poppins';



}
@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.prog_img{
  width: 25vw;
  margin-left: 6.25vw;
}
.soutenez_close{
  height: 100vh;
  width: 100vw;
  background-color: #27272741;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2999;
  display: none;

}
.div_soutenez{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: black;
  padding: 1em;
  border-radius: .5em;
  z-index: 3000;
  display: none;
}
.mail_soutien{
  text-decoration: underline;
  color: rgb(215, 215, 255);
  cursor: pointer;
}
.div_soutenez h5{
 position:initial;
 margin: 0;
 padding: 0;
 transform: unset;
 margin-top: 1em;

}
.navbar{
  animation: .5s ease-out 0s 1 slideInTop;
  background-color: transparent;

  position:relative;
  height: 10vh;
  display: flex;
  transition: .3s all ease-in-out;

}
.fixed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  background-color: transparent;
}
.logopropos{
  width: 15vw;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1em;
}
@media (max-width:768px) {
  .logopropos{
    width: 30vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1em;
  }
}
@media (max-width:527px) {
  .logopropos{
    width: 35vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1em;
  }
}
@media (max-width:425px) {
  .logopropos{
    width: 40vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1em;
  }
}

.navbar img{

  width: 15vw;
  height: 3vw;
  margin-top: 1em;
  border-radius: .3em;

 margin-left: 3em;

  z-index: 100;
}
ul{
  display: flex;
  width: 80vw;
  background-color: transparent;
  justify-content: space-around;
  position: absolute;
  right: 0;
  top: 50%;

  transform: translateY(-50%);
}
li{
  list-style: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  transition: .3s all ease-in-out;
  font-size: 1.2rem;
}
.App-header{
  background: url('https://firebasestorage.googleapis.com/v0/b/rebecq-vivant.appspot.com/o/dev%2Fhomepage.jpg?alt=media&token=374c66a7-943f-49a9-971d-be350eefcb69');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: relative;
}
.title-div{
  position: absolute;
  bottom: 2%;
  left: 2%;
}
@keyframes slideInLefth1 {
  0% {
    transform: translate(-50%,100%);
  }
  100% {
    transform: translate(-50%,0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.home_h1{
  animation: 1s ease-out 0s 1 slideInLeft;
  color: white;
  font-size: 9rem;
  font-weight: 900;
  text-shadow: 1px 1px 30px black;
  line-height: 1.2;
}
@media (max-width:860px) {
  .home_h1{
    animation: 1s ease-out 0s 1 slideInLefth1;
    color: white;
    font-size: 9rem;
    font-weight: 900;
    text-shadow: 1px 1px 30px black;
    line-height: 1.2;
  }
}
@media (max-width:557px) {
  .div_soutenez h5{
    font-size: 1rem;
  } 
  .div_soutenez h5{
    font-size: 1rem;
  } 
}


h2{
  color: white;
  animation: 1s ease-out 0s 1 slideInLeft;
  font-weight: 400;
  font-size: 3rem;
  text-shadow: 1px 1px 30px black;
}
.member-div{
  animation: 1s ease-out 0s 1 slideInRight;
  text-align: right;
  position: absolute;
  bottom: 2%;
  right: 2%;
}
.member-div button{
  cursor: pointer;
  background-color: transparent;
  color: white;
  margin-right: 1em;
  border: .2em solid white;

  padding: 1.5em;
  padding-top: .6em;
  padding-bottom: .6em;

  font-size: 2rem;
  font-weight: 800;
}
h3{
  color: white;
  font-weight: 300;
  font-size: 4.5rem;
  text-shadow: 1px 1px 30px black;
}
h4{
  font-size: 3rem;
  width: 100vw;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 8vh;
}
.principes{
  margin-top: 10vh;
  width: 100vw;
  height: 60vh;
  position: relative;
}
.wrap-list-principes{
  margin-top: unset;
  background-color: black;
  position: initial;
  width: 95%;
  height: 45vh;
  margin-left: 50vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  box-shadow: 1px 1px 30px black;
}
.principe{

  height: 100%;
  width: 25%;
  text-align: center;
  position: relative;


}
.principe1{

  position: fixed;
  left: 0;
  bottom: 0;
  transition: .3s all ease-in-out;
  background-image: url('/public/tranceparence.jpg');

  transition: .3s all ease-in-out;
  background-size: cover;

}
@media (min-width:860px) {

  .principe1:hover{
  
   background-image: none;
   background-color: #141414;
   height: 120%;
   text-align: center;
  }
  
  .principe1:hover> #principe1-h5{
    transform: translate(0,0);
    position: static;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .principe1:hover> #principe1-p{
    display: block;
    color: white;
    text-align: left;
    font-size: 1.1rem;
  }
  .principe2:hover{
  
   background-image: none;
   background-color: #141414;
   height: 120%;
   text-align: center;
  }
  
  .principe2:hover> #principe2-h5{
    transform: translate(0,0);
    position: static;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .principe2:hover> #principe2-p{
    display: block;
    color: white;
    text-align: left;
    font-size: 1.1rem;
  }
  .principe3:hover{
  
   background-image: none;
   background-color: #141414;
   height: 120%;
   text-align: center;
  }
  
  .principe3:hover> #principe3-h5{
    transform: translate(0,0);
    position: static;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .principe3:hover> #principe3-p{
    display: block;
    color: white;
    text-align: left;
    font-size: 1.1rem;
  }
  .principe4:hover{
  
   background-image: none;
   background-color: #141414;
   height: 120%;
   text-align: center;
  }
  
  .principe4:hover> #principe4-h5{
    transform: translate(0,0);
    position: static;
    font-size: 1.4rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .principe4:hover> #principe4-p{
    display: block;
    color: white;
    text-align: left;
    font-size: 1.1rem;
  }
}
.principe2{

  position: fixed;
  left: 25%;
  bottom: 0;
  background-image: url('/public//carrièrequenast.jpg');
  transition: .3s all ease-in-out;
  background-size: cover;


}
.principe2:hover{

  background-image: none;
  background-color: #141414;


}
@keyframes minimize {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(0);
  }
}
@keyframes maximize {
  0%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
}
.principe3{
  background-image: url('/public/patrimoine.jpg');
  background-size: cover;

  position: fixed;
  left: 50%;
  bottom: 0;
  transition: .3s all ease-in-out;

}
.principe3:hover{
  background-image: none;
 background-color: #141414;

}
.principe4{
  background-image: url('/public/mobilitérebecq.png');
  background-size: cover;
  position: fixed;
  left: 75%;
  bottom: 0;
  transition: .3s all ease-in-out;

}
.principe4:hover{
  background-image: none;
 background-color: #141414;

}
h5{
  font-size: 1.5rem;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  transition: .3s all ease-in-out;

}
.principe h5{
  font-size: 1.4rem;
}
.principe_p{
  color: white;
  display: none;
  transition: .3s all ease-in-out;
  font-size: 1rem;
  padding-left: 1em;
  padding-right: 1em;
  width: 90%;
}
.h4cause{
  margin-bottom: 10vh;
}
.actu{
  text-align: left;
  height: fit-content ;
  margin-top: 10vh;
}
.actu h4{
  text-align: left;
  margin-left: 3em;
}
.h4action{
  margin-top: -20vh;
}
.propos_home{
  margin-top: 10vh;
}
.p_propos_home{
  color: black;

  margin: 0;
  padding: 0;
  position: initial;
  width: 90vw;
  display: block;
  text-align: center;
  margin-left: 50%;
  font-size: 1.3rem;
  transform: translateX(-50%);
}
.video{
  margin-top: 5em;
  height: 80vh;
  width: 100vw;
  background-color: #009ADE;
  position: relative;
}
.video video{
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: .3em;

}
.combats-div{
  margin-top: 30vh;
}
.grid{
    display: grid;
    grid-template-columns: fit-content fit-content;
    grid-template-rows: 3.75vh 3.75vh 3.75vh   3.75vh 3.75vh 3.75vh  3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh   3.75vh 3.75vh 3.75vh  3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh 3.75vh ;
}


.img-combats {
  background: url('/public/backcombats.png') ;
  background-size: 100%;
}
.un{
  cursor: pointer;
  grid-row: 1/10;
  grid-column: 1/2;
  background-color: #009ADE;
}
.deux{
  grid-row: 1/9;
  grid-column: 2/3;
}
.trois{
  grid-row: 10/18;
  grid-column: 1/2;
}
.wrap-card-program{

}
.quatre{
  grid-row: 9/18;
  grid-column: 2/3;
  background-color: #00AB9F;
  cursor: pointer;

}
.cinq{
  grid-row: 17/26;
  grid-column: 1/2;
  background-color: #CA29C5;
  cursor: pointer;
}
.six{
  grid-row: 18/25;
  grid-column: 2/3;
}
.sept{
  grid-row: 26/33;
  grid-column: 1/2;
}
.huit{
  grid-row: 24/33;
  grid-column: 2/3;
  background-color: #009ADE;
  cursor: pointer;
}

h6{
  color: white;
  font-size: 2rem;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 7.5vh;
  margin-left: 2em;
  position: relative;
  width: 70%;
  font-style: italic;

}
.underline{
  position: absolute;
  height: 2px;
  width: 50%;
  background-color: white;
  transition: .3s all ease-in-out;
  top: -2%;
}
.underline-right{
  right: 1em;
}
.noir{

  transition: .3s all ease-in-out;
}
.rouge{

  transition: .3s all ease-in-out;
  text-align: right;
}
.noir:hover{

}
.rouge:hover{

}

.programme_home{

  margin-bottom: 3em;

}
.wrap-card-program{
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  overflow-x: scroll;
  padding-top: 1em;
  padding-bottom: 1em;


}
.programme_home h4{
  text-align: left;
  margin-left: 4em;
}

footer{
  background-color: #272727;
  width: 100vw;
  height: 25vh;
  text-align: center;
  padding-top: 4%;
}
.nav_footer{
  position: initial;
  margin: 0;
  padding: 0;
  width: 50vw;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 5%;
}
.nav_footer li{
  color: #D3D3D3;

}
.socials_footer{
  cursor: pointer;
}
.socials_footer{
  justify-content: space-evenly;
  display: flex;

  width: 10%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.socials_footer img{
  height: 40px;
}
.pub_Neodia_Web{
  width: 100vw;
  background-color: black;
  margin: 0;
  padding: 0;
}
.actu_facebook_iframe{

  width: 90%;
}
.actu_facebook_iframe{
  position: block;
  height: 80vh;
}
.pub_Neodia_Web h6{
  font-size: 1rem;
  font-style: normal;
  text-align: left;
  margin: 0;
  font-weight: 400;
}
.pub_Neodia_Web h6 span{
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
}
.gauche{
  height: 30px;
  position: absolute;
  left:5%;
  top: 65%;
  z-index: 100;
  transform: translateY(-50%);
  transform: rotate(180deg);
}
.droite{
  height: 30px;
  position: absolute;
  right:5%;
  top: 70%;
  z-index: 100;
  transform: translateY(-50%);
}
.fleche{
  background-color: black;
  padding: 1em;
  border-radius: 1em;
  cursor: pointer;

}
.prog_titre{
  font-size: 1.5rem;
}
.programme_home{
  position: relative;

}
.wrap-card-program{
  position: relative;
  scroll-behavior: smooth;
}

@media (max-width:1024px) {
  .member-div button{
    font-size: 1.5rem;
  }
  .home_h1{
    font-size: 17vh
  }
  .title-div h2{
    font-size: 7vh
  }
}
@media (max-width:1055px) {
  .video video{
    height: 70%;
  }
}

@media (max-width:926px) {
  .actu_facebook_iframe{


  }
  .nav_footer{
    width: 100vw;
  }
  .prog_titre{
    font-size: 1rem;
  }
}
.programme_home{
  padding-top: 1em;
  padding-bottom: 1em;

}
.mobile_button{
  display: none;
}
.hamburger{
  display: none;
}
.combats-div{
  margin-top: 30vh;
}
@media (max-width:1250px) {
  .combats-div{
    margin-top: 20vh;
  }
}
@media (max-width:854px) {
  .combats-div{
    margin-top: 10vh;
  }
  .actu_facebook_iframe{
    height: 150vh;
  }
}
@media (max-width:854px) {
  .combats-div{
    margin-top: 10vh;
  }
  .actu_facebook_iframe{
    height: 150vh;
  }
}
@media (max-width:860px) {
  .home_h1{
    text-align: center;

    font-size: 16vh;
    padding:0;
    transform: translateX(-50%);
    margin-left: 50%;
    width: fit-content;
    

  }
  .title-div h2{
    text-align: center;
  }
  .h4action{
    margin-top: -0vh;
  }
  .combats-div{

  }
  .video video{
    height: 90%;
    max-width: 90%;

  }
  .wrap-list-principes{
    display: block;
    background-color: transparent;
    width: 90vw;
    height: fit-content;
    position: initial;
    margin-left: 50vw;
    box-shadow: none;
    margin-top: unset;
  }
  .propos_home{
    margin-top: 10vh;
  }
  .principe{
    position: relative;
    width: 100%;
    padding-top:2em ;
    height: fit-content;
    padding-bottom: 2em;
    margin-top: 2em;
    border-radius: 1em;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.396);
  }
  .principe2{
    left: 0;
  }
  .principe h5{
    text-shadow: 1px 1Px 10px black;
    filter: brightness(1);
  }
  .principe{
    filter: brightness(0.9);

  }
  .principe_p{
    display: block;
    text-shadow: 1px 1Px 10px black;
    filter: brightness(1);
  }
  .principe h5{
    position: initial;
    margin: 0;
    transform: translate(0,0);
  }
  .principes{
    height: 100vh;
  }
  .actu_facebook_iframe{

  }
  .actu{
    height: 150vh;
  }
  .hamburger{
    display: block;
    position: absolute;

  }
  .hamburger{
    width: 40px;
    transition: .3s all ease-in-out;

    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    position: fixed;
    top: 2%;
    border-radius: .3em;
    right: 2%;
    z-index: 1000000;
  }
  .hamburger div{
    margin-top: .8em;
    width: 40px;
    height: 2px;
    background-color: black;

    top: 2%;
    right: 2%;

  }
  #header{
    display: none;
    transition: .3s all ease-in-out;
    animation-duration: 1s;
    background-color: black;
    height: 100vh;

  }
  ul{
    display: block;
    width: unset;
    height: 40vh
  }
  .navbar{
    position: initial;
  }
  .navbar img{
    margin-left: 50%;
    transform: translate(-50%,100%);
    margin-top: 2em;
    width: auto;
    height: 3em;
    border-radius: none;
  }
  li{
    margin-top: 5vh;
    text-align: center;
    width: 100vw;
  }
  .prog_img{
    width: 25vw;
  }
  @keyframes disapear {
    0%{
      filter:opacity(1);
      }
      100%{
      filter: opacity(0);

    }
  }
  .nav_footer{
    display: block;
  }
  .nav_footer li{
    margin: 0;
  }
  footer{
    height: fit-content;
    padding-bottom: 2em;
  }
  .programme_home h4{
    text-align: center;
    margin-left: 0;
  }
  .actu h4{
    text-align: center;
    margin-left: 0;
  }
  .un{
    cursor: pointer;
    grid-row: 1/10;
    grid-column: 1/3;
    background-color: #009ADE;
  }
  .deux{
    grid-row: 1/9;
    grid-column: 2/3;
    display: none;
  }
  .trois{
    grid-row: 10/18;
    grid-column: 1/2;
    display: none;
  }
  .quatre{
    grid-row: 9/18;
    grid-column: 1/3;
    background-color: #00AB9F;
    cursor: pointer;
  }
  .cinq{
    grid-row: 17/26;
    grid-column: 1/3;
    background-color: #CA29C5;
    cursor: pointer;
  }
  .six{
    grid-row: 18/26;
    grid-column: 2/3;
    display: none;
  }
  .sept{
    display: none;
  }
  .huit{
    grid-row: 25/34;
    grid-column: 1/3;
  }
  .wrap-card-program{
    display: flex;


    margin: 0;

  }

  .prog_img{
    width: 30vw;
    margin-left: 10vw;

  }
  .principe3{
    left: unset;
  }
  .principe4{
    left: unset;
  }
  .principe{
    position:unset;
  }
  .programme_home{
    padding-left: 0;
    padding-right: 0;
  }
  .title-div{
    position: initial;
    margin-left: 50%;

    transform: translateX(-50%);
  }
  .App-header{
    padding-top: 20vh;
    height: 80vh;
  }
  .member-div{
    display: block;
    left: 2%;
    right: unset;
  }
  .socials_footer{
    width: unset;
  }
  .principes{
    height: 140vh;
  }

}
@media (max-width:783px) {
  .actu_facebook_iframe{
    height: 110vh;
  }
}
@media (max-width:674px) {
  h6{
    font-size: 1.5rem;
  }
  .actu_facebook_iframe{
    height: 100vh;
  }
}

@media (max-width:635px) {
  .home_h1{
    font-size: 10vh;


    text-align: center;
  }
  .title-div{
    width: fit-content;
  }
  h2{
    text-align: center;
  }
  .title-div h2{
    font-size: 5vh;
  }
  .actu{
    margin-top: 0vh;
    height: 140vh;
  }
  .propos_home{
    margin-top: 10vh;
  }
  .div_soutenez{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: black;
    padding: 1em;
    border-radius: .5em;
    z-index: 3000;
    display: none;
  }
  .mail_soutien{
    text-decoration: underline;
    color: rgb(215, 215, 255);
    cursor: pointer;
  }
  .div_soutenez h5{
   position:initial;
   margin: 0;
   padding: 0;
   transform: unset;
   margin-top: 1em;
   

  }
  .div_soutenez h3{
    font-size: 3rem;
  }
  .principes{
    height: 150vh;
  }
}
@media (max-width:587px) {
  .actu{
    height: 135vh ;
  }
}
@media (max-width:536px) {
  .actu{
    height: 128vh ;
  }
  .actu_facebook_iframe{
    height: 90vh;
  }
}
@media (max-width:499px) {
  .actu{
    height: 120vh ;
  }
  .actu_facebook_iframe{
    height: 90vh;
  }
}
@media (max-width:452px) {
  .actu{
    height: 115vh ;
  }
  .actu_facebook_iframe{
    height: 80vh;
  }
}
@media (max-width:396px) {
  .actu{
    height: 110vh ;
  }
  .actu_facebook_iframe{
    height: 70vh;
  }
}
@media (max-width:375px) {
  .actu{
    height: 104vh ;
  }
  .actu_facebook_iframe{
    height: 70vh;
  }
}
@media (max-width:375px) {
  .actu{
    height: 100vh ;
  }
}
@media (max-width:541px) {
   .prog_img{
    width: 50vw;
   }
}
@media (max-width:491px) {
  .actu{
    margin-top: 0vh;
  }
}
@media (max-width:425px) {


}
@media (max-width:419px) {
  .propos_home{
    margin-top: 10vh;
  }
  .actu{
    margin-top: 10vh;
  }
}
@media (max-width:401px) {
  h6{
    font-size: 1.3rem;
  }
}
@media (max-width:375px) {
  .actu{
    margin-top: 10vh;
  }
  .prog_img{
    width: 60vw;
  }
}
@media (max-width:362px) {
  .home_h1{
    font-size: 4rem;
  }
}
@media (max-width:327px) {
  .member-div button{
    font-size: 1.2rem;
  }
}
