.h1_contact{
    margin-top: 35vh;
    margin-left: 5vw;
    text-shadow: none;
}
.wrap_formulaire{
    background-color: #F9F7F7;
    height: fit-content;
    width: 50vw;
    margin-left: 5vw;
    margin-top: 10vh;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.627);
    border-radius: 1em;
    padding: 0;
    padding: 1Em;
    padding-top: 2em;
}
.validation{
    color: rgb(4, 194, 4);
    margin-left: 4.75%;
    display: none;
}
.wrap_contact{
    display: flex;
    margin-bottom: 10vh;
}
.conditions_div{
    position: fixed;
    z-index: 4000;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    box-shadow: 1px 1px 10px #00000062;
    width: fit-content;
    padding: 1em;
    border-radius: 1em;
    display: none;
    
}
.titrepoli{
    text-decoration: none;
    font-size: 2rem;
    color: black;
    font-weight: 800;
}
.rem_cond{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.289);
    z-index: 3999;
    display: none;
}
.conditions_div p{
    width:auto;
    color: rgb(57, 57, 57);
    height: 60vh;
    overflow-y: scroll;

    
}
input{
    width: 25%;
    margin-left: 4.75%;
    height: 5vh;
    border-radius: .5em;
    border: none;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.563);
    padding-left: 2%;
}
textarea{
    margin-left: 4.75%;
    margin-top: 2vh;
    width: 89%;
    height: 35vh;
    border-radius: .5em;
    border: none;
    resize: none;
    padding-left: 2%;
    padding-top: 2%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.563);
}
.btn_contact{
    margin-left: 4.75%;
    padding: .4em;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: .3em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    background-color: #1BADED;
    color: white;
    border: none;
    margin-top: .5em;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.563);
    cursor: pointer;
    transition: .3s all ease-in-out
}
.btn_contact:hover{
    background-color: #159cd6;
   
}

.info_contact{
    width: 10vw;
    height: 10vw;
    background-color: #F9F7F7;
    width: fit-content;
    height: fit-content;
    padding: 1em;
    border-radius: .5em;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.627);
    padding-left: 1.5em;
    margin-top: 10vh;
    padding-right: 3em;
    margin-left: 10vw;
}
.cochez-p{
    color: red;
    display: none;
    margin-left: 4.75%;
}
.info_contact_h5{
    position: initial;
    transform: none;
    color: black;
    font-size: 1.2rem;
    width: fit-content;
    align-items: center;margin-top: 1em;
}
.conditions{
    text-decoration: underline;
    color: blue;
}
.checkbox{
    padding: 0;
    background-color: none;
    box-shadow: none;
    height: unset;
    width: unset;
}
.info_contact_p{
    position: initial;
    transform: none;
    color: black;
    text-decoration: underline;
    font-size: 1rem;
    width: fit-content;
}
@media (max-width:733px) {
    .h1_contact{
        font-size: 3rem;
        margin-left: 40%;
        transform: translateX(-50%);
    }
    .wrap_contact{
        display: block;
    }
    .wrap_formulaire{
        width: 80vw;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .info_contact{

        margin-left: 50%;
        transform: translateX(-50%);
    }
}